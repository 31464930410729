import authReducer from './authReducer'
import userReducer from './userReducer'
import { combineReducers } from 'redux'
//firestoreReducer syncs redux state from store with Firestore database state
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    user: authReducer,
    profile: userReducer,
    firebase: firebaseReducer,
})

export default rootReducer
