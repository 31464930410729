import React, { Component } from 'react';
import { MDBFooter } from "mdbreact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from 'react-redux'
import NavigationBarPage from "./components/NavigationBarPage";
import Routes from "./Routes";

import './App.css';
import './btn.css';

class App extends Component {

  constructor() {
    super()
    this.state = {}
}

  componentDidMount(props) {
  }

  state = {
    collapseID: "",
  }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

  closeCollapse = collapseID => () =>
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });

  render() {

    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );

    const { collapseID } = this.state;

    return (
      <Router>
        <div className="flyout">
          <Route exact path="/app/*" render={ () => <NavigationBarPage />} />
          {collapseID && overlay}
          <main className="main-container">
            <Routes />
          </main>
          <MDBFooter color="elegant-color">
            <p className="footer-copyright mb-0 py-3 text-center">
              &copy; {new Date().getFullYear()} Copyright: <a href="https://aboardme.com"> AboardMe.com </a>
            </p>
          </MDBFooter>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(App)
