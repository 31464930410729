/**
 * Email auth types
 */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

/**
 * phoneAuth action types
 */
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER'
export const UPDATE_CODE = 'UPDATE_CODE'
export const SENDING_CODE = 'SENDING_CODE'
export const PHONE_VERIFICATION_SUCCESS = 'PHONE_VERIFICATION_SUCCESS'
export const PHONE_LOGIN_ERROR = 'PHONE_LOGIN_ERROR'
export const PHONE_CODE_SENT = 'PHONE_CODE_SENT'

/**
 * socialAuth action types
 */
export const FACEBOOK_LINKED = 'FACEBOOK_LINKED'
export const FACEBOOK_LINKING = 'FACEBOOK_LINKING'
export const FACEBOOK_LINK_ERROR = 'FACEBOOK_LINK_ERROR'
export const TWITTER_LINKED = 'TWITTER_LINKED'
export const TWITTER_LINKING = 'TWITTER_LINKING'
export const TWITTER_LINK_ERROR = 'TWITTER_LINK_ERROR'
export const SOCIAL_ACCOUNT_UNLINKED = 'SOCIAL_ACCOUNT_UNLINKED'
export const SOCIAL_ACCOUNT_UNLINKED_ERROR = 'SOCIAL_ACCOUNT_UNLINKED_ERROR'
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER'

/**
 * Singout action
 */
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_ERROR = 'SIGNOUT_SUCCESS_ERROR'

/**
 * User Profile
 */

export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED'
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED'
export const HANDLE_CHANGE = 'HANDLE_CHANGE'
