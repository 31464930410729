import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'

export default function ProfilePageForm(props) {
    const { profile, handleChange, handleSubmit } = props
    const { firstName, lastName, email, phoneNumber } = profile
    return (
        <MDBRow>
            <MDBCol md="12">
                <form onSubmit={handleSubmit}>
                    <MDBRow className="mt-3">
                        <MDBCol md="6">
                            <label htmlFor="firstName">First name</label>
                            <input
                                type="text"
                                id="firstName"
                                className="form-control"
                                value={firstName}
                                onChange={e =>
                                    handleChange({ firstName: e.target.value })
                                }
                            />
                        </MDBCol>
                        <MDBCol md="6">
                            <label htmlFor="lastName">Last name</label>
                            <input
                                type="text"
                                id="lastName"
                                className="form-control"
                                value={lastName}
                                onChange={e =>
                                    handleChange({ lastName: e.target.value })
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-3">
                        <MDBCol md="12">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                value={email}
                                onChange={e =>
                                    handleChange({ email: e.target.value })
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-3">
                        <MDBCol md="12">
                            <label htmlFor="phone">Phone</label>
                            <input
                                readOnly
                                type="text"
                                id="phoneNumber"
                                className="form-control"
                                value={phoneNumber}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="text-center pt-3">
                        <MDBCol md="12">
                            <MDBBtn color="primary" type="submit">
                                Update
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </form>
            </MDBCol>
        </MDBRow>
    )
}
