import React, { Component } from 'react'
import { Coach } from '../config/RealEstateOptions'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LinkSocMedia from '../components/LinkSocMedia'
import LinkTwitter from '../components/LinkTwitter'
import ProfilePageCard from '../components/ProfilePageCard'
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact'

class ProfilePage extends Component {
    state = {
        Coach: Coach,
    }
    render() {
        const { auth } = this.props
        if (!auth.uid) return <Redirect to="/login" />
        return (

                <MDBContainer className="pt-3">
                    <MDBRow>
                        <MDBCol md="12">
                            <ProfilePageCard />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="pt-3">
                        <MDBCol md="6">
                            <LinkSocMedia />
                        </MDBCol>
                        <MDBCol md="6">
                            <LinkTwitter />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        firebase: state.firebase,
    }
}

export default connect(mapStateToProps)(ProfilePage)
