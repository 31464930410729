export const ImmigrationStatusOptions = [
    {
        'id': 1, 
        'value': 'Thinking about moving to Canada'
    }, 
    {
        'id': 2, 
        'value': 'Moving to Canada as a Permanent Resident'
    },
    {
        'id': 3, 
        'value': 'Moving to Canada on student or work visa'
    },
    {
        'id': 4,
        'value': 'Moving to Canada on a spousal or family sponsorship visa'
    }
]

export const LearnAboutOptions = [
    {
        'id': 1, 
        'value': 'Facebook Ad'
    }, 
    {
        'id': 2, 
        'value': 'Google Search'
    }, 
    {
        'id': 3, 
        'value': 'TCET Website'
    }, 
    {
        'id': 4, 
        'value': 'In-person Event'
    }, 
    {
        'id': 5, 
        'value': 'Family/Friends'
    }, 
    {
        'id': 6, 
        'value': 'Other (please specify)'
    }
]

export const ProvinceOptions = [
    {
        'id': 1, 
        'value': 'Alberta'
    },
    {
        'id': 2, 
        'value': 'British Columbia'
    },
    {
        'id': 3, 
        'value': 'Manitoba'
    },
    {
        'id': 4, 
        'value': 'New Brunswick'
    },
    {
        'id': 5, 
        'value': 'Newfoundland and Labrador'
    },
    {
        'id': 6, 
        'value': 'Nova Scotia'
    },
    {
        'id': 7, 
        'value': 'Ontario'
    },
    {
        'id': 8, 
        'value': 'Prince Edward Island'
    },
    {
        'id': 9, 
        'value': 'Quebec'
    },
    {
        'id': 10, 
        'value': 'Saskatchewan'
    },
    {
        'id': 11,
        'value': 'Nunavut'
    },
    {
        'id': 12,
        'value': 'Yukon'
    }, 
    {
        'id': 13,
        'value': 'Northwest Territories'
    } 

]