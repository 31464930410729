import { newUserDataModel } from '../../config/newUserDataModel'
import * as types from './actionTypes'

export const signIn = credentials => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        firebase
            .auth()
            .signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(() => {
                dispatch({ type: types.LOGIN_SUCCESS })
            })
            .catch(err => {
                dispatch({ type: types.LOGIN_ERROR, err })
            })
    }
}

export const updatePhoneNumber = phoneNumber => {
    return { type: types.UPDATE_PHONE_NUMBER, phoneNumber }
}

export const updateCode = code => {
    return { type: types.UPDATE_CODE, code }
}

/**
 * Signin with phoneNumber
 */
export const signInWithPhoneNumber = (phoneNumber, appVerifier) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()
        dispatch({ type: types.SENDING_CODE, isSending: true })
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function(confirmationResult) {
                dispatch({ type: types.PHONE_CODE_SENT, confirmationResult })
            })
            .catch(function(error) {
                dispatch({ type: types.PHONE_LOGIN_ERROR, error })
            })
    }
}

/**
 * Confirming phone verification code
 */
export const confirm = (confirmationResult, code) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore()
        if (confirmationResult && code) {
            dispatch({ type: types.SENDING_CODE, isSending: true })
            confirmationResult
                .confirm(code)
                .then(result => {
                    firestore
                        .collection('users')
                        .doc(result.user.uid)
                        .get()
                        .then(doc => {
                            const _doc = doc.data()
                            if (!_doc) {
                                return firestore
                                    .collection('users')
                                    .doc(result.user.uid)
                                    .set(newUserDataModel)
                            }
                        })
                        .then(() => {
                            dispatch({
                                type: types.PHONE_VERIFICATION_SUCCESS,
                                confirmationResult,
                            })
                        })
                        .catch(error => {
                            dispatch({ type: types.PHONE_LOGIN_ERROR, error })
                        })
                })
                .catch(function(error) {
                    dispatch({ type: types.PHONE_LOGIN_ERROR, error })
                    // User couldn't sign in (bad verification code?)
                    // ...
                })
        }
    }
}

/**
 * Linking FAcebook account
 */
export const linkFacebook = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        const provider = new firebase.auth.FacebookAuthProvider()
        dispatch({ type: types.FACEBOOK_LINKING })
        firebase
            .auth()
            .currentUser.linkWithPopup(provider)
            .then(result => {
                if (result.credential) {
                    const facebookToken = result.credential.accessToken
                    return firestore
                        .collection('accessTokens')
                        .doc(result.user.uid)
                        .update({ facebookToken })
                }
            })
            .then(result => {
                dispatch({ type: types.FACEBOOK_LINKED, result })
            })
            .catch(error => {
                dispatch({ type: types.FACEBOOK_LINK_ERROR, error })
                console.log(error)
            })
    }
}

/**
 * Linking Twitter account
 */
export const linkTwitter = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        const provider = new firebase.auth.TwitterAuthProvider()
        dispatch({ type: types.TWITTER_LINKING })
        firebase
            .auth()
            .currentUser.linkWithPopup(provider)
            .then(result => {
                if (result.credential) {
                    const twitterToken = result.credential.accessToken
                    return firestore
                        .collection('accessTokens')
                        .doc(result.user.uid)
                        .update({ twitterToken })
                }
            })
            .then(result => {
                dispatch({ type: types.TWITTER_LINKED, result })
            })
            .catch(error => {
                dispatch({ type: types.TWITTER_LINK_ERROR, error })
                console.log(error)
            })
    }
}

export const unlinkSocialAccount = providerId => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()
        const user = firebase.auth().currentUser
        user.unlink(providerId)
            .then(function() {
                // Auth provider unlinked from account
                dispatch({ type: types.SOCIAL_ACCOUNT_UNLINKED })
            })
            .catch(function(error) {
                // An error happened
                dispatch({ type: types.SOCIAL_ACCOUNT_UNLINKED_ERROR, error })
            })
    }
}

/**
 * General Aboardme website log out
 */
export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        firebase
            .auth()
            .signOut()
            .then(() => {
                firebase.logout()
                dispatch({ type: types.SIGNOUT_SUCCESS })
            })
            .catch(function(error) {
                // An error happened
                dispatch({ type: types.SIGNOUT_ERROR, error })
            })
    }
}
