import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import { firebaseConfigProd, firebaseConfigDev } from '../../constants/firebase'

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import rootReducer from '../../store/reducers/rootReducer'
import logger from 'redux-logger'

const prodConfig = firebaseConfigProd
const devConfig = firebaseConfigDev
const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

class Firebase {
    constructor() {
        app.initializeApp(config)
        this.RecaptchaVerifier = app.auth.RecaptchaVerifier
        this.confirmationResult = app.auth.confirmationResult
        this.auth = app.auth()
        this.db = app.database()
    }
}

export default Firebase


export function configureStore(initialState) {
    const rrfConfig = {
        useFirestoreForProfile: true,
        userProfile: 'users',
        attachAuthIsReady: true,
    }
    const middleWares = [
        thunk.withExtraArgument({ getFirebase, getFirestore }),
    ]
    app.initializeApp(config)
    return createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleWares),
            reactReduxFirebase(app, rrfConfig), // redux binding for firebase
            reduxFirestore(app) // redux bindings for firestore
        )
    )
}