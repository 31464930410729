import React, { Component } from 'react'
import firebaseStorage from 'firebase/app'
import 'firebase/storage'
import Loader from 'react-loader-spinner'
import FileUploader from 'react-firebase-file-uploader'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from './Firebase'
import { connect } from 'react-redux'
import { updateUserAvatar } from '../store/actions/userActions'
import { MDBRow, MDBCol } from 'mdbreact'
import './AvatarUploader.css'
import defaultAvatar from '../assets/defaultAvatar.png'

class AvatarUploader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isUploading: false,
            progress: 0,
            avatarURL: '',
        }
    }

    componentDidMount() {
        const { photoURL } = this.props.auth
        this.setState({ avatarURL: photoURL ? photoURL : defaultAvatar })
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 })
    handleProgress = progress => this.setState({ progress })
    handleUploadError = error => {
        this.setState({ isUploading: false })
        console.error(error)
    }
    handleUploadSuccess = filename => {
        const { updateUserAvatar, auth } = this.props
        this.setState({ avatar: filename, progress: 100, isUploading: false })
        firebaseStorage
            .storage()
            .ref(auth.uid)
            .child('avatar')
            .child(filename)
            .getDownloadURL()
            .then(url => {
                updateUserAvatar({ avatar: url })
                this.setState({ avatarURL: url })
            })
    }

    render() {
        const { auth } = this.props
        const { avatarURL, isUploading } = this.state
        let loader
        if (isUploading) {
            loader = (
                <div className="avatar-loader">
                    <Loader
                        className="avatar-loader"
                        type="Puff"
                        color="#ccc"
                        height="160"
                        width="160"
                    />
                </div>
            )
        } else {
            loader = (
                <img
                    src={avatarURL}
                    className="avatar mx-auto d-block"
                    alt="aligment"
                />
            )
        }
        return (
            <div>
                <form>
                    <MDBRow>
                        <MDBCol md="12" className="avatar-holder">
                            <label htmlFor="file-input">{loader}</label>
                            <FileUploader
                                accept="image/*"
                                name="avatar"
                                className="file-input"
                                id="file-input"
                                randomizeFilename
                                storageRef={firebaseStorage
                                    .storage()
                                    .ref(auth.uid)
                                    .child('avatar')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                                maxWidth="1024"
                                maxHeight="1024"
                            />
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.user.authError,
        firebase: state.firebase,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserAvatar: data => dispatch(updateUserAvatar(data)),
    }
}

export default compose(
    withRouter,
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(AvatarUploader)
