import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import '../SignupPage.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../components/Firebase'
import CustomDatePicker from '../../components/CustomDatePicker'
import TextArea from '../../components/TextArea'
import Toggle from '../../components/Toggle'
import {
    ImmigrationStatusOptions,
    LearnAboutOptions,
    ProvinceOptions,
} from '../../config/SignUpOptions'
import SelectOptions from '../../components/SelectOptions'
import AvatarUploader from '../../components/AvatarUploader'
import { updateUserProfile } from '../../store/actions/userActions'

class Step1Page extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            state: '',
            province: '',
            country: '',
            city: '',
            about: '',
            immigrationStatus: '',
            arrivalDate: new Date(),
            learnAbout: '',
            isLanded: false,
            isSoftLanding: false,
            acceptTerms: false,
        }
    }

    componentDidMount() {
        const { firebase } = this.props
        if (!firebase.profile.isEmpty) {
            // console.log(firebase.profile);
            const {
                firstName,
                lastName,
                email,
                phoneNumber,
                city,
                state,
                province,
                country,
                about,
                immigrationStatus,
                arrivalDate,
                learnAbout,
                isLanded,
                isSoftLanding,
                acceptTerms,
            } = firebase.profile
            this.setState({
                firstName,
                lastName,
                email,
                phoneNumber,
                city,
                state,
                province,
                country,
                about,
                immigrationStatus,
                arrivalDate,
                learnAbout,
                isLanded,
                isSoftLanding,
                acceptTerms,
            })
        }
    }

    handleChange = e => {
        if (e.target) {
            this.setState({
                [e.target.id]: e.target.value,
            })
        }
    }
    handleSubmit = e => {
        e.preventDefault()
        console.log(this.state)
        const { updateUserProfile } = this.props
        updateUserProfile({ ...this.state })
    }
    render() {
        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            city,
            state,
            province,
            country,
            about,
            immigrationStatus,
            arrivalDate,
            learnAbout,
            isLanded,
            isSoftLanding,
            acceptTerms,
        } = this.state

        return (
            <div className="step step1">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="12" className="text-center">
                            <h2>Account Information</h2>
                        </MDBCol>
                        <MDBCol md="12" className="text-center">
                            <AvatarUploader />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="12">
                            <form onSubmit={this.handleSubmit}>
                                <MDBRow className="mt-3">
                                    <MDBCol md="6">
                                        <label htmlFor="firstName">
                                            First name
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className="form-control"
                                            value={firstName}
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <label htmlFor="lastName">
                                            Last name
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className="form-control"
                                            value={lastName}
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="12">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="12">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            className="form-control"
                                            value={phoneNumber}
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="12">
                                        <label htmlFor="immigrationStatus">
                                            What is your Immigration Status?
                                        </label>
                                        <SelectOptions
                                            options={ImmigrationStatusOptions}
                                            handleChange={this.handleChange}
                                            id="immigrationStatus"
                                            value={immigrationStatus}
                                            selectedOption={immigrationStatus}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="6">
                                        <label htmlFor="landed">
                                            Have you landed?
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <Toggle
                                            id="isLanded"
                                            checked={isLanded}
                                            handleChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="6">
                                        <label htmlFor="arrivalDate">
                                            What was your arrival date?
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <CustomDatePicker
                                            id="arrivalDate"
                                            selectedDate={new Date()}
                                            value={arrivalDate}
                                            handleChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="6">
                                        <label htmlFor="softLanding">
                                            Is this a soft landing?
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <Toggle
                                            id="isSoftLanding"
                                            checked={isSoftLanding}
                                            handleChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="6">
                                        <label htmlFor="softLanding">
                                            What province do you{' '}
                                            {!isLanded ? 'want to' : ''} live
                                            in?
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <SelectOptions
                                            options={ProvinceOptions}
                                            handleChange={this.handleChange}
                                            id="province"
                                            selectedOption={province}
                                            value={province}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-3">
                                    <MDBCol md="12">
                                        <label htmlFor="softLanding">
                                            Personal Information
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            id="city"
                                            value={city}
                                            placeholder="City of Origin"
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            id="state"
                                            value={state}
                                            placeholder="State / Province of Origin"
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            id="country"
                                            value={country}
                                            placeholder="Country of Origin"
                                            onChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-4">
                                    <MDBCol md="12">
                                        <label htmlFor="softLanding">
                                            How did you learn about
                                            <strong> AboardMe</strong>?
                                        </label>
                                    </MDBCol>
                                    <MDBCol md="12">
                                        <SelectOptions
                                            options={LearnAboutOptions}
                                            handleChange={this.handleChange}
                                            id="learnAbout"
                                            value={learnAbout}
                                            selectedOption={learnAbout}
                                        />
                                    </MDBCol>
                                    <MDBCol md="12">
                                        <TextArea
                                            id="about"
                                            value={about}
                                            handleChange={this.handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <div className="form-check mr-sm-2 py-4">
                                            <Toggle
                                                className="form-check-input"
                                                id="acceptTerms"
                                                checked={acceptTerms}
                                                handleChange={this.handleChange}
                                            />
                                            <label
                                                className="form-check-label ml-2 v-align-top"
                                                htmlFor="inlineFormCheck"
                                            >
                                                I have read & agree to the{' '}
                                                <a href="/tearms">
                                                    Terms of Use and Privacy
                                                    Policy
                                                </a>
                                            </label>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="text-center">
                                    <MDBCol md="12">
                                        <MDBBtn color="primary" type="submit">
                                            Update
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        firebase: state.firebase,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserProfile: data => dispatch(updateUserProfile(data)),
    }
}

export default compose(
    withRouter,
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Step1Page)
