export const RealEstateAgents = [
    {
        name: 'Riaz Ghani',
        profession: 'Real Estate Agent',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage: 'https://i.imgur.com/BSXMzpR.jpg',
        description: '',
    },
    {
        name: 'Sam McDadi',
        profession: 'Real Estate Agent',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage:
            'https://rankmyagent.com/media/2017/09/sam-e1505841442476-300x300.png',
        description: '',
    },
    {
        name: 'Neveen Faress',
        profession: 'Real Estate Agent',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage:
            'https://s3-media3.fl.yelpcdn.com/bphoto/Y_uT5fNAFI3ett5jD85S_g/ls.jpg',
        description: '',
    },
    {
        name: 'Sabrina Khan',
        profession: 'Real Estate Agent',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage:
            'https://media.licdn.com/dms/image/C4D03AQETDf7dLO_rPg/profile-displayphoto-shrink_800_800/0?e=1558569600&v=beta&t=iR6QJW5WffEiU_t9qPxrZQyqBw0MarOnVifrH9c9Mpg',
        description: '',
    },
]

export const TaxConsultants = [
    {
        name: 'Fernando Hill',
        profession: 'Tax Consultant',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage: 'https://randomuser.me/api/portraits/men/33.jpg',
        description: '',
    },
    {
        name: 'Danielle Pierce',
        profession: 'Tax Consulatnt',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage: 'https://randomuser.me/api/portraits/women/33.jpg',
        description: '',
    },
    {
        name: 'Cherly Kelly',
        profession: 'Tax Consulatnt',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage: 'https://randomuser.me/api/portraits/women/29.jpg',
        description: '',
    },
    {
        name: 'Andrew Chambers',
        profession: 'Tax Consultant',
        contactNumber: '',
        email: '',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage: 'https://randomuser.me/api/portraits/men/39.jpg',
        description: ``,
    },
]

export const Coach = [
    {
        firstName: 'Kunal', 
        lastName: 'Kamble',
        profession: 'Entrepreneur',
        contactNumber: '+1 416 123 4567',
        email: 'email@gmail.com',
        socialURL: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            website: '',
        },
        profileImage:
            'https://i1.wp.com/kunalkamble.com/wp-content/uploads/2014/08/IMG_4484.jpg?w=900',
        description: '',
        country: 'Canada'
    },
]
