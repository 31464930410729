import React from "react";
import { MDBContainer } from "mdbreact";
import StepZilla from "react-stepzilla";
import "./SignupPage.css";
import Step1Page from "./SignupPageSteps/Step1Page";
import Step2Page from "./SignupPageSteps/Step2Page";
import Step3Page from "./SignupPageSteps/Step3Page";

class SignuPage extends React.Component {
  steps = [
    {name: 'Step 1', component: <Step1Page />},
    {name: 'Step 2', component: <Step2Page />},
    {name: 'Step 3', component: <Step3Page />},
  ];
  stepChange = (step) => {
    console.log(step);
    switch(step) {
      case 1:
        // console.log(step);
        break;
      
      default:
        break;
    }
  }
  render() {
    return (
      <MDBContainer fluid className="signup-container text-center py-2">
        <div className="aboardme-logo"></div>
        <div className='step-progress'>
            <StepZilla steps={this.steps}
              onStepChange={(step) => this.stepChange(step)} 
              nextTextOnFinalActionStep={"Signup"} />
        </div>
      </MDBContainer>
    );
  }
}

export default SignuPage;
