import React from "react";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import './UserCard.css';

class UserCard extends React.Component {

  render() {
    return (
      <MDBRow className="user-cards" style={{ margin: "1rem 0rem" }}>
        {
          this.props.users.map((user, index) => {
            return (
              <MDBCol key={index} md="3">
                <MDBCard>
                  <MDBCardImage className="img-fluid img-thumbnail" src={user.profileImage || this.props.state.defaultProfileImage} waves />
                  <MDBCardBody>
                    <MDBCardTitle className="title">{user.firstName} {user.lastName}</MDBCardTitle>
                    <p className="font-weight-bold blue-text">{user.profession}</p>

                    <hr />
                    <ul className="list-inline my-0">
                    <li className="list-inline-item">
                        <a href="#!" className="p-2 fa-lg li-ic">
                          <MDBIcon icon="linkedin" brand />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#!" className="p-2 fa-lg tw-ic">
                          <MDBIcon icon="twitter" brand />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#!" className="p-2 fa-lg ins-ic">
                          <MDBIcon icon="instagram" brand />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#!" className="p-2 fa-lg yt-ic">
                          <MDBIcon icon="youtube" brand />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#!" className="p-2 fa-lg fb-ic">
                          <MDBIcon icon="facebook" brand />
                        </a>
                      </li>
                    </ul>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            )
          })
        }
        
      </MDBRow>
    )
  }
}

export default  UserCard;
