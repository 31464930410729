export const newUserDataModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: '',
    country: '',
    city: '',
    about: '',
    immigrationStatus: '',
    arrivalDate: new Date(),
    learnAbout: '',
    isLanded: false,
    isSoftLanding: false,
    acceptTerms: false,
    initials: '',
    avatar: '',
    showPeerMatch: false,
    makeMyProfilePublic: false,
    occupational: {
        industry: '',
        profession: '',
        isJobSecured: false,
        education: [],
        workExperience: [],
    },
    immigration: {
        status: '',
        isLanded: false,
        landingDate: '',
        isSoftLanding: false,
        preferredState: '',
        arrivingWithKids: false,
        havePlaceToLive: false,
        canadianDocuments: {
            photoId: false,
            sinCard: false,
            prCard: false,
            healthCard: false,
            drivingLicense: false,
            speakFrench: false,
            improveEnglish: false,
            changeCarrier: false,
            upgradeSkills: false,
            interest: [], // job, networking, phone plans, insurance
        },
    },
    home: {
        city: '',
        state: '',
        country: '',
    },
    current: {
        city: '',
        state: '',
        country: '',
    },
    socialProfiles: {
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        youtube: '',
    },
}