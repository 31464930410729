import React from 'react'
import { MDBCol, MDBRow, MDBBadge } from 'mdbreact'
import UserCard from '../components/UserCard'
import './DashboardPage.css'
import { Redirect } from 'react-router-dom'
//Redux imports
import { connect } from 'react-redux'
import {
    RealEstateAgents,
    TaxConsultants,
    Coach,
} from '../config/RealEstateOptions'

class DashboardPage extends React.Component {
    /**
     *
     * Look at https://randomuser.me/ for UI and DataStructure
     *
     *  */

    state = {
        RealEstateAgents: RealEstateAgents,
        TaxConsultants: TaxConsultants,
        Coach: Coach,
        defaultProfileImage:
            'https://mdbootstrap.com/img/Photos/Others/images/43.jpg',
    }

    updateData = name => {
        let newCoach = this.state.coach[0]
        newCoach.name = name
        this.setState({
            coach: [newCoach],
        })
    }
    render() {
        const { auth } = this.props
        if (!auth.uid) return <Redirect to="/login" />
        return (
            <div className="dashboard">
                <MDBRow>
                    <MDBCol md="12">
                        <h5 className="section-title">My Coach</h5>
                        <UserCard users={this.state.Coach} state={this.state} />
                    </MDBCol>

                    <MDBCol md="12">
                        <hr />
                    </MDBCol>

                    <MDBCol md="12">
                        <h5 className="section-title">
                            Connections matched for{' '}
                            <MDBBadge pill color="default">
                                Real Estate
                            </MDBBadge>
                        </h5>
                        <UserCard
                            users={this.state.RealEstateAgents}
                            state={this.state}
                        />
                    </MDBCol>

                    <MDBCol md="12">
                        <hr />
                    </MDBCol>

                    <MDBCol md="12">
                        <h5 className="section-title">
                            Connections matched for{' '}
                            <MDBBadge pill color="primary">
                                Tax Consultant
                            </MDBBadge>
                        </h5>
                        <UserCard
                            users={this.state.TaxConsultants}
                            state={this.state}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

//Redux puts state into props that can be used by the component above
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        firebase: state.firebase,
    }
}

export default connect(mapStateToProps)(DashboardPage)
