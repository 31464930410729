import React from "react";
import { MDBContainer } from "mdbreact";
import "../SignupPage.css";

class Step2Page extends React.Component {
  render() {
    return (
      <div className="step step2">
        <MDBContainer fluid className="text-center py-5">
          <h1>
            AboardMe is completely free,
            <br/>
            sign up today!
          </h1>
        </MDBContainer>
      </div>
    );
  }
}

export default Step2Page;