import * as types from '../actions/actionTypes'

const initState = {
    isSending: false,
    isInvalidPhone: true,
    phoneNumber: '',
    appVerifier: '',
    code: '',
    requestSent: false,
    error: null,
    user: null,
    isCodeSent: false,
    confirmationResult: null,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case types.UPDATE_PHONE_NUMBER:
        const phoneNumber = action.phoneNumber
        const isInvalidPhone =
            phoneNumber === undefined ||
            phoneNumber === '' ||
            phoneNumber.length < 12
        return {
            ...state,
            phoneNumber,
            isInvalidPhone,
        }
        case types.UPDATE_CODE:
        return {
            ...state,
            code: action.code,
        }
        case types.SENDING_CODE:
            return {
                ...state,
                isSending: action.isSending,
            }
        case types.PHONE_LOGIN_ERROR:
            return {
                ...state,
                authError: 'Login Failed',
            }

        case types.PHONE_CODE_SENT:
            const isCodeSent = action.confirmationResult ? true : false
            return {
                ...state,
                confirmationResult: action.confirmationResult,
                authError: null,
                isCodeSent,
                isSending: false,
            }

        case types.PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                authError: null,
            }

        case types.SIGNOUT_ERROR:
            return {
                ...state,
                authError: action.error.message,
            }

        case types.SIGNOUT_SUCCESS:
            return {
                ...state,
                authError: null,
            }

        case types.FACEBOOK_LINK_ERROR:
            return {
                ...state,
                authError: action.error.message,
            }

        case types.FACEBOOK_LINKED:
            return {
                ...state,
                result: action.result,
                authError: null,
            }
        case types.SOCIAL_ACCOUNT_UNLINKED:
            return {
                ...state,
            }
        case types.SOCIAL_ACCOUNT_UNLINKED_ERROR:
            return {
                ...state,
                error: action.error,
            }
        case types.TWITTER_LINK_ERROR:
            return {
                ...state,
                authError: action.error.message,
            }

        case types.TWITTER_LINKED:
            return {
                ...state,
                result: action.result,
                authError: null,
            }

        default:
            return state
    }
}

export default authReducer
