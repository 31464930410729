import * as types from '../actions/actionTypes'

const initState = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case types.HANDLE_CHANGE:
            return {
                ...state,
                ...action.data,
            }
        case types.USER_PROFILE_UPDATED:
            return {
                ...state,
                data: action.data,
            }
        case types.USER_PROFILE_FAILED:
            return {
                ...state,
                authError: 'Profile Update Failed',
            }

        default:
            return state
    }
}

export default userReducer
