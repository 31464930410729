import React from "react";
import { Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from "./pages/PageNotFound";
import ProfilePage from "./pages/ProfilePage";
import ChatPage from "./pages/ChatPage";
import UserSignOut from "./pages/UserSignOut";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" render={ () => <HomePage />} />
        <Route exact path="/login" render={ () => <LoginPage />} />
        <Route exact path="/signup" render={ () => <SignupPage />} />
        <Route exact path="/signout" render={ () => <UserSignOut />} />
        <Route exact path='/terms-and-conditions' render={ () => <TermsAndConditions /> } />
        <Route exact path='/privacy-policy' render={ () => <PrivacyPolicy /> } />
        {/* APP Routes */}
        <Route exact path="/app/dashboard" render={ () => <DashboardPage />} />
        <Route exact path="/app/profile" render={ () => <ProfilePage />} />
        <Route exact path="/app/chat" render={ () => <ChatPage />} />
        <Route render={ () => <PageNotFound />} />
      </Switch>
    );
  }
}

export default Routes;
