import React from "react";
import { MDBFreeBird, MDBCol, MDBRow, MDBCardBody } from "mdbreact";
import "./HomePage.css";

class HomePage extends React.Component {
  render() {
    return (
      <div className="coming-soon">
        <MDBFreeBird>
          <MDBRow>
            <MDBCol
              md="6"
              className="mx-auto float-none z-depth-0 py-2 px-2"
            >
              <MDBCardBody>
                <div className="aboardme-logo"></div>
                <h2 className="coming-soon-text">COMING SOON</h2>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        <div className="hire">
          <h3>WE'RE <span>HIRING</span></h3>
          <h3><span>SUPERHEROES</span></h3>
          <hr />
          <a href="https://angel.co/aboardme/jobs" target="_blank" rel="noopener noreferrer"><h4>JOIN OUR TEAM</h4></a>
        </div>
      </div>
    );
  }
}

export default HomePage;
