import React, { Component } from 'react';

class SelectOptions extends Component {

    render() {
        const { id, handleChange, options, selectedOption } = this.props;
        return (
            <div>
                <select className="browser-defualt custom-select form-control form-control-lg" value={selectedOption} id={id} onChange={handleChange}>
                <option option=""  >Select an option</option>
                {options.map((option, index) => {
                    return (
                        <option option={option.id} key={index} defaultValue={selectedOption} state={this.state}>{option.value}</option>
                    )
                })}
                
                </select>
            </div>
        )
    }
}

export default SelectOptions;