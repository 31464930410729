import React, { Component } from 'react'
import { MDBContainer, MDBCard, MDBCardBody, MDBCol, MDBRow, MDBIcon } from 'mdbreact'
import Switch from 'react-switch'
import { linkTwitter, unlinkSocialAccount } from '../store/actions/authActions'
import { connect } from 'react-redux'
import './LinkSocMedia.css'

class LinkTwitter extends Component {
    state = {
        error: null,
        provider: '',
        result: '',
    }

    constructor(props) {
        super(props)
        const { hasTwitter } = this.props
        this.state = { checked: hasTwitter }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(checked) {
        this.setState({ checked })
        if (checked) {
            this.props.linkTwitter()
        } else {
            const { unlinkSocialAccount } = this.props
            unlinkSocialAccount('twitter.com')
        }
    }

    render() {
        const { checked } = this.state
        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12">
                        <MDBCard>
                        <MDBCardBody className="">
                                <MDBRow className="socMediaRow">
                                    <h3 className="pl-4 pt-3">
                                        <MDBIcon fab icon="twitter-square" />{' '}
                                        Connect{' '}
                                    </h3>
                                    <label className="pr-4 pt-3">
                                    <Switch
                                            onChange={this.handleChange}
                                            checked={checked}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="material-switch"
                                            //accessibility label for users that use screen readers
                                            aria-label="toggle switch to sign in with your Facebook account"
                                        />
                                    </label>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.user.authError,
        firebase: state.firebase,
        result: state.firebase.auth.credential,
        hasTwitter: state.firebase.auth.providerData.filter(provider => {
            if (provider.providerId === 'twitter.com') return provider
        }).length
            ? true
            : false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        linkTwitter: () => dispatch(linkTwitter()),
        unlinkSocialAccount: providerId =>
            dispatch(unlinkSocialAccount(providerId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkTwitter)
