import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css"; 

class CustomDatePicker extends Component {

  render() {
    const { id, handleChange, selectedDate } = this.props;
    return (
      <DatePicker
        id={id}
        className="form-control"
        selected={selectedDate}
        onChange={(value) => handleChange({target: { id: id, value: value}})}
      />
    );
  }
}

export default CustomDatePicker;


