import React, { Component } from 'react'
import { signOut } from '../store/actions/authActions'
import { connect } from 'react-redux'
import Redirect from 'react-router-dom/Redirect';

class UserSignOut extends Component {
    render() {
        const { signOut } = this.props
        signOut()
        return <Redirect to='/login' />
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(signOut()),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(UserSignOut)
