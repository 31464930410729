import React from 'react'
import BrandLogo from '../components/BrandLogo'

export default function PrivacyPolicy() {
    return (
        <div class="container __small" id="main">
            <div className="text-center mb-4">
                <BrandLogo />
            </div>

            <h1>Privacy Policy</h1>
            <p class="updated">
                Effective date: 5/23/2012, last edit: 05/21/2018
            </p>
            <p>
                Please review our Privacy Policy, which is part of the{' '}
                <a href="/terms-and-conditions/">Terms and Conditions</a>. The
                Privacy Policy describes how we collect and handle any
                information gathered from users of the Services. By accessing or
                using the Service, including browsing the site, you expressly
                consent to the collection, use, storage, processing, and
                disclosure of your information in accordance with our Privacy
                Policy.
            </p>
            <p>
                For purposes of this Policy, unless otherwise defined herein,
                capitalised terms used in this Policy shall have the same
                meaning as defined in the Terms and Conditions.
            </p>
            <h2>1. Introduction</h2>
            <p>
                Aboardme, Inc. Privacy Policy contains information about
                collecting Customers, Visitors and other data subjects (like
                Agents or End-users) Personal Data and other information,
                products and Services offered or performed by Aboardme, Inc. and
                the manner of its processing. We respect the right to privacy of
                our software users and take reasonable steps for the Visitors
                and Customers to be familiar with the manner in which rendered
                accessible information via the following websites{' '}
                <a href="https://www.aboardme.com/">www.aboardme.com</a>, and
                applications is processed. Due to the implementation of
                comprehensive security regulations, Personal Data provided by
                our Services, websites and application is of highest security
                standards. The acceptance of Terms and Conditions for the use of
                any Aboardme, Inc. Services or deciding on using or purchasing
                any Service offered by Aboardme, Inc. means accepting Aboardme,
                Inc. Privacy Policy.
            </p>
            <p>
                Aboardme, Inc. participates in and complies with the EU-U.S.
                Privacy Shield Framework and the Swiss-U.S. Privacy Shield
                Framework as set forth by the U.S. Department of Commerce
                regarding the collection, use, and retention of personal
                information transferred from the European Union and Switzerland
                to the United States. Aboardme, Inc. has certified to the
                Department of Commerce that it adheres to the Privacy Shield
                Principles, and in particular to the Privacy Shield Principles
                of:
            </p>
            <ol>
                <li>Notice,</li>
                <li>Choice,</li>
                <li>Accountability for Onward Transfer,</li>
                <li>Security,</li>
                <li>Data Integrity and Purpose Limitation,</li>
                <li>Access,</li>
                <li>Recourse, Enforcement and Liability.</li>
            </ol>
            <p>
                Therefore, we are committed to subjecting all Personal Data
                received from European Union (EU) member countries and
                Switzerland, in reliance on the Privacy Shield Framework, to the
                Framework’s applicable Principles, as mentioned above. If there
                is any conflict between the policies in this Privacy Policy and
                the Privacy Shield Principles, the Privacy Shield Principles
                shall govern. To learn more about the Privacy Shield program,
                and to view our certification page, please visit{' '}
                <a href="https://www.privacyshield.gov/">
                    www.privacyshield.gov
                </a>
                .
            </p>
            <h2>2. Scope</h2>
            <p>
                This Privacy Policy is effective to all information collected by
                Aboardme / Timvork Inc. from which an individual can be
                identified (Personal Information/Personal Data). This Privacy
                Policy also applies to all Personal Data received by Aboardme /
                Timvork Inc. in the United States from EEA (European Economic
                Area) and Switzerland.
            </p>
            <h2>3. Notice</h2>
            <p>
                Aboardme / Timvork Inc. is responsible for the processing of
                Personal Data it receives under the Privacy Shield Framework and
                subsequently transfers to a Third Party Service Provider acting
                as an agent on its behalf. We comply with the Privacy Shield
                Principles for all onward transfers of Personal Data from the EU
                and Switzerland, including the onward transfer liability
                provisions. With respect to Personal Data received or
                transferred pursuant to the Privacy Shield Framework, we are
                subject to the regulatory enforcement powers of the U.S. Federal
                Trade Commission. In certain situations, we may be required to
                disclose Personal Data in response to lawful requests by public
                authorities, including to meet national security or law
                enforcement requirements. Please be aware we may disclose
                Personal Data to respond to subpoenas, court orders or legal
                process, or to establish or exercise our legal rights or defend
                against legal claims. We may also disclose Personal Information
                if it is necessary in order to prevent, investigate or take
                action regarding illegal activities, or as otherwise required by
                law. To learn more about the Privacy Shield Framework, visit the
                U.S. Department of Commerce’s Privacy Shield website:
                <a href="https://www.privacyshield.gov/welcome">
                    {' '}
                    www.privacyshield.gov/welcome
                </a>
                . A list of Privacy Shield participants is maintained by the
                Department of Commerce and is available at:{' '}
                <a href="https://www.privacyshield.gov/list">
                    www.privacyshield.gov/list
                </a>
                . To view our certification page, please visit the
                aforementioned website. Under certain conditions, more fully
                described on the Privacy Shield website,{' '}
                <a href="https://www.privacyshield.gov">
                    www.privacyshield.gov
                </a>{' '}
                you may invoke binding arbitration when other dispute resolution
                (referred to below) procedures have been exhausted.
            </p>
        </div>
    )
}
