import React, { Component } from 'react'
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../components/Firebase'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    updatePhoneNumber,
    updateCode,
    signInWithPhoneNumber,
    confirm,
} from '../../store/actions/authActions'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import EnterPhoneNumberForm from './EnterPhoneNumberForm'
import EnterConfirmCodeForm from './EnterConfirmCodeForm'
import './SignInWithPhone.css'

const SignInWithMobile = () => <SignInWithMobileForm />

class SignInFormBase extends Component {
    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
            }
        )
        window.recaptchaVerifier.render().then(function(widgetId) {})
    }

    onConfirmCode = event => {
        const { confirmationResult, code } = this.props.user
        if (confirmationResult && code) {
            this.props.confirm(confirmationResult, code)
        } else {
            console.log('invalid confirmation')
        }
    }

    onSubmit = event => {
        const { phoneNumber } = this.props.user
        this.props.signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
        event.preventDefault()
    }

    render() {
        const { auth, updatePhoneNumber, updateCode } = this.props
        if (auth.uid) return <Redirect to="/app/dashboard" />

        return (
            <MDBContainer className="mt-5 text-center login-with-phone">
                <MDBRow center>
                    <MDBCol md="6">
                        <form>
                            <p className="h4 text-center mb-4">
                                Let's get started
                            </p>

                            <EnterPhoneNumberForm
                                user={this.props.user}
                                updatePhoneNumber={updatePhoneNumber}
                                onSubmit={this.onSubmit}
                            />
                            <EnterConfirmCodeForm
                                user={this.props.user}
                                updateCode={updateCode}
                                onConfirmCode={this.onConfirmCode}
                            />
                            <div
                                id="recaptcha-container"
                                type="button"
                                className="hide recaptcha"
                            />
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        user: state.user,
        firebase: state.firebase,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signInWithPhoneNumber: (phoneNumber, appVerifier) =>
            dispatch(signInWithPhoneNumber(phoneNumber, appVerifier)),
        confirm: (confirmationResult, code) =>
            dispatch(confirm(confirmationResult, code)),
        updatePhoneNumber: phoneNumber =>
            dispatch(updatePhoneNumber(phoneNumber)),
        updateCode: code => dispatch(updateCode(code)),
    }
}

const SignInWithMobileForm = compose(
    withRouter,
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SignInFormBase)
export default SignInWithMobile
export { SignInWithMobileForm }
