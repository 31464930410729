import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import PhoneInput from 'react-phone-number-input'
import SmartInput from 'react-phone-number-input/smart-input'

function EnterPhoneNumberForm(props) {
    const { updatePhoneNumber, onSubmit } = props
    const { phoneNumber, isCodeSent, isSending, isInvalidPhone } = props.user
    return (
        <MDBRow
            center
            className={
                isCodeSent ? 'animated fadeOutUp' : 'animated fadeInDown'
            }
        >
            <MDBCol md="12" className="mt-4">
                <PhoneInput
                    name="phoneNumber"
                    country="CA"
                    inputComponent={SmartInput}
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    maxLength="14"
                    onChange={phoneNumber => updatePhoneNumber(phoneNumber)}
                />
                <br />
                <MDBBtn
                    color="green"
                    onClick={onSubmit}
                    disabled={isCodeSent || isInvalidPhone || isSending}
                >
                    Get Code
                </MDBBtn>
            </MDBCol>
        </MDBRow>
    )
}

export default EnterPhoneNumberForm
