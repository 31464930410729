import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from './Firebase'
import AvatarUploader from './AvatarUploader'
import { updateUserProfile, handleChange } from '../store/actions/userActions'
import './ProfilePageCard.css'
import ProfilePageForm from './ProfilePageForm'

class ProfilePageCard extends React.Component {
    componentDidUpdate() {
        const { profile } = this.props.firebase
        if (!this.props.profile.firstName && profile.firstName) {
            const { firstName, lastName, email, phoneNumber } = profile
            this.props.handleChange({ firstName, lastName, email, phoneNumber })
        }
    }
    componentDidMount() {
        const { profile } = this.props.firebase
        if (!this.props.profile.firstName && profile.firstName) {
            const { firstName, lastName, email, phoneNumber } = profile
            this.props.handleChange({ firstName, lastName, email, phoneNumber })
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        const { updateUserProfile } = this.props
        updateUserProfile({ ...this.props.profile })
    }

    render() {
        const { handleChange } = this.props

        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol md="12" className="text-center">
                        <h2>Account Information</h2>
                    </MDBCol>
                    <MDBCol md="12" className="text-center">
                        <AvatarUploader />
                    </MDBCol>
                </MDBRow>
                <ProfilePageForm
                    profile={this.props.profile}
                    handleChange={handleChange}
                    handleSubmit={this.handleSubmit}
                />
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    const { firebase, profile } = state
    return {
        firebase,
        profile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserProfile: data => dispatch(updateUserProfile(data)),
        handleChange: data => dispatch(handleChange(data)),
    }
}

export default compose(
    withRouter,
    withFirebase,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ProfilePageCard)
