import * as types from './actionTypes'

export const handleChange = data => {
    return { type: types.HANDLE_CHANGE, data }
}

export const updateUserAvatar = data => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()
        const auth = getState().firebase.auth
        if (data.avatar && auth.uid) {
            var user = firebase.auth().currentUser
            user.updateProfile({ photoURL: data.avatar })
                .then(function() {
                    // Update successful.
                })
                .catch(function(error) {
                    dispatch({ type: types.USER_PROFILE_FAILED, error })
                })
        }
    }
}

export const updateUserProfile = data => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore()
        const auth = getState().firebase.auth

        if (data && auth.uid) {
            firestore
                .collection('users')
                .doc(auth.uid)
                .update({
                    ...data,
                })
                .then( () => {
                    updateUserAvatar(data)
                    dispatch({ type: types.USER_PROFILE_UPDATED, data })
                })
                .catch(error => {
                    dispatch({ type: types.USER_PROFILE_FAILED, error })
                })
        }
    }
}
