import React, { Component } from 'react'
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavItem,
    MDBNavLink,
} from 'mdbreact'
import Logo from '../assets/am-mini.png'
import { connect } from 'react-redux'

class NavigationBarPage extends Component {
    state = {
        collapseID: '',
        navItems: [
            { path: '/team', title: 'Our Team' },
            { path: '/blog', title: 'Blog' },
        ],
        authMenu: [
            { path: '/signup', title: 'Sign Up' },
            { path: '/login', title: 'Login' },
        ],
        navUserItems: [
            { path: '/app/chat', title: 'Chat' },
            { path: '/app/profile', title: 'Profile' },
            { path: '/signout', title: 'Sign Out' },
        ],
    }

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : '',
        }))

    closeCollapse = collapseID => () =>
        this.state.collapseID === collapseID &&
        this.setState({ collapseID: '' })

    render() {
        const { auth } = this.props
        const href = auth.isEmpty ? '/' : '/app/dashboard';
        const { authMenu, navUserItems } = this.state

        const authUserMenu = navUserItems.map(function(item) {
            return (
                <MDBNavItem key={item.title}>
                    <MDBNavLink exact to={item.path}>
                        {item.title}
                    </MDBNavLink>
                </MDBNavItem>
            )
        })

        const authMenuItems = authMenu.map(function(item) {
            return (
                <MDBNavItem key={item.title}>
                    <MDBNavLink
                        exact
                        to={item.path}
                        // onClick={this.closeCollapse("mainNavbarCollapse")}
                    >
                        {item.title}
                    </MDBNavLink>
                </MDBNavItem>
            )
        })
        const userMenu = auth.isEmpty ? authMenuItems : authUserMenu
        return (
            <MDBNavbar
                color="elegant-color"
                dark
                expand="md"
                fixed="top"
                scrolling
            >
                <MDBNavbarBrand href={href}>
                    <img alt="AboardMe" src={Logo} className="brand-logo" />
                    AboardMe
                </MDBNavbarBrand>
                <MDBNavbarToggler
                    onClick={this.toggleCollapse('mainNavbarCollapse')}
                />
                <MDBCollapse
                    id="mainNavbarCollapse"
                    isOpen={this.state.collapseID}
                    navbar
                >
                    <MDBNavbarNav right>{userMenu}</MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps)(NavigationBarPage)
