import React from 'react'
import BrandLogo from '../components/BrandLogo';

export default function TermsAndConditions() {
    return (
        <div>
            <article class="container __small">
                <div className="text-center mb-4">
                    <BrandLogo />
                </div>
                <p>Last updated: 08/11/2018</p>{' '}
                <p>
                    This Aboardme / Timvork Inc. Terms and Conditions
                    constitutes an Agreement and is established between Aboardme
                    / Timvork Inc. and the entity or person willing to use and
                    accessing the Service. Aboardme / Timvork Inc. and/or its
                    affiliates provides to be a subject of subject to the
                    following Terms. Please review it carefully. By accepting
                    these Terms and Conditions, you confirm you have read,
                    understood and accepted its contents.
                </p>{' '}
                <p>
                    Please also review our Privacy Policy, which is part of
                    these Terms and can be viewed{' '}
                    <a href="/privacy-policy">here</a>. The Privacy Policy
                    describes how we collect and handle any information gathered
                    from users of the Services. By accessing or using the
                    Service, including browsing the site, you accept the
                    Agreement and expressly consent to the collection, use,
                    storage, processing, and disclosure of your information in
                    accordance with our Privacy Policy.
                </p>{' '}
                <p>
                    As used in this Agreement, "Aboardme / Timvork Inc." [also
                    "Company" or "we" (or "Provider" in the Data Protection
                    Addendum)] means Aboardme / Timvork Inc. The worldwide
                    corporate headquarters and principal office of the Company
                    shall be Boston, Massachusetts, address: One International
                    Place, Suite 1400, Boston, MA 02110-2619, United States of
                    America.
                </p>{' '}
                <h2 id="definitions">Definitions</h2>{' '}
                <p>
                    The following terms shall have the meanings specified below:
                </p>{' '}
                <p>
                    "Services" means the services and products supplied by the
                    Company to the Customer under the Agreement specified below.
                </p>{' '}
                <p>
                    "Customer", "you" (or "Client" in a Data Protection
                    Addendum) means any user, person or entity who creates an
                    account and uses Aboardme / Timvork Inc. Services supplied
                    by the Company under the Agreement.
                </p>{' '}
                <p>
                    "Visitor" means any person who is browsing any website where
                    Aboardme / Timvork Inc. Services are installed.
                </p>{' '}
                <p>
                    "Agent" means any person who is employed by Customer to
                    operate the Service.
                </p>{' '}
                <p>
                    "End-user" means any person who uses Aboardme / Timvork Inc.
                    Services in purpose to communicate with the Customer.
                </p>{' '}
                <p>
                    "Personal Data/Personal Information" means personal data
                    within the meaning of the Regulation (EU) <sup>2016</sup>⁄
                    <sub>679</sub> of the European Parliament and of the Council
                    of 27 April 2016 on the protection of natural persons with
                    regard to the processing of personal data and on the free
                    movement of such data and repealing Directive 95/46/EC (The
                    General Data Protection Regulation – GDPR), processed by
                    Aboardme / Timvork Inc. (or a Sub-processor) on behalf of
                    the Customer pursuant to or in connection with the
                    Agreement.
                </p>{' '}
                <p>
                    "Confidential Information" means all information, whether
                    written or oral, provided by the disclosing party to the
                    receiving party and which (i) is known by the receiving
                    party to be confidential; (ii) is marked as or stated to be
                    confidential; or (iii) ought reasonably to be considered by
                    the receiving party to be confidential.
                </p>{' '}
                <p>
                    "Third Party Service Provider" means any third party that
                    collects, process and/or uses Personal Information under the
                    instruction of Aboardme / Timvork Inc. including any
                    consultant, representative, advisor, or independent
                    contractor (including Sub-processors) who renders services
                    to the Company, a subsidiary, or an affiliate.
                </p>{' '}
                <p>
                    "Sub-processor" means entities which software, goods or
                    services are used by the Company in order to run a business,
                    in particular while providing the Services.
                </p>{' '}
                <p>
                    All terms derived from General Data Protection Regulation as
                    "Commission", "Controller", "Data Subject", "Member State",
                    "Personal Data", "Personal Data Breach", "Processing",
                    "Processor" should be understood in line with their regular
                    meaning coming from the regulation.
                </p>{' '}
                <h2 id="general-statements">General statements</h2>{' '}
                <ol>
                    <li>
                        On principles set out in this Terms and Conditions
                        Aboardme / Timvork Inc. renders the following Services:
                        Aboardme, ChatBot and Knowledge Base via the following
                        Internet websites{' '}
                        <a href="https://www.aboardme.com">aboardme.com</a>,{' '}
                        <a href="https://www.chatbot.com">chatbot.com</a> and{' '}
                        <a href="https://www.knowledgebase.ai">
                            knowledgebase.ai
                        </a>{' '}
                        or via mobile applications.
                    </li>{' '}
                    <li>
                        Accepting these Terms and Conditions is a condition to
                        use Services provided by Aboardme / Timvork Inc.
                    </li>{' '}
                    <li>
                        All Customers are obliged to observe these Terms and
                        Conditions.
                    </li>{' '}
                    <li>
                        By indicating the Customer's acceptance towards this
                        Agreement, accessing or using the Service, the Customer
                        agrees to be bound by all terms, conditions, notices
                        contained or referenced in this Agreement.
                    </li>{' '}
                    <li>
                        Please keep in mind that Aboardme / Timvork Inc. may
                        modify the provisions of these Terms and Condition and
                        only those currently visible on our website are up to
                        date and valid at the time. However, modification shall
                        not adversely affect the main provisions of the
                        Agreement such as terms of payment or termination of
                        Services. Such changes take places with prior notifying
                        the Customer and – if not clearly rejected – are treated
                        as accepted. Therefore, we encourage you to periodically
                        familiarize with the currently effective Terms and
                        Conditions version on our Internet websites.
                    </li>{' '}
                    <li>
                        Further use of Services after additional modifications
                        in the Terms and Conditions have been implemented, shall
                        constitute the consent to accept these modifications.
                        Refusal to accept these modifications shall preclude the
                        Customer from using the Services.
                    </li>
                </ol>{' '}
                <h2 id="services-description">Services description</h2>{' '}
                <ol>
                    <li>
                        The software used by the Customer in accordance with its
                        application and purpose defined in these Terms and
                        Conditions, available on the following websites{' '}
                        <a href="https://www.aboardme.com">aboardme.com</a>,{' '}
                        <a href="http://www.chatbot.com">chatbot.com</a> and{' '}
                        <a href="http://www.knowledgebase.ai">
                            knowledgebase.ai
                        </a>{' '}
                        and provided by Aboardme / Timvork Inc. shall be deemed
                        as Services.
                    </li>{' '}
                    <li>
                        The use of any new Services available on aforementioned
                        website, after the Customer has accepted the Terms and
                        Conditions shall be subject to its provisions.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. is exclusively entitled to
                        decide on functionality, the use, subject matter and the
                        range of particular Services as well as to cease
                        rendering the Services.
                    </li>{' '}
                    <li>
                        The Company is exclusively authorized to decide on the
                        contents and the nature of the software as well as to
                        freely add, change or remove particular elements.
                    </li>
                </ol>{' '}
                <h2 id="access-and-the-use-of-services">
                    Access and the use of Services
                </h2>{' '}
                <ol>
                    <li>
                        Aboardme / Timvork Inc. Services are exclusively
                        designated for business use and must be used only in
                        accordance with their purpose, application and the Terms
                        and Conditions.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. Services may be used only in
                        line with its original purpose and aim. Detailed
                        guidelines concerning proper use of the Services are
                        enlisted in Acceptable Use Policy section below.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. Services can be accessed solely
                        by logging on particular website.
                    </li>{' '}
                    <li>
                        Every Customer is assigned a particular password and
                        login which must not be used by third parties without
                        the Customer's consent. The Customer is responsible for
                        keeping and proper protection of his password and login.
                    </li>{' '}
                    <li>
                        The Customer must be at least 13 years of age to be able
                        to register and to access his or her account.
                    </li>{' '}
                    <li>
                        The Customer undertakes to use the Services in
                        accordance with their use, purpose and in the manner
                        consistent with both these Terms and Conditions and
                        provisions of currently effective law.
                    </li>{' '}
                    <li>
                        The Customer bears full responsibility for all contents,
                        phrases and entries added to the network in connection
                        with the use of offered Services.
                    </li>{' '}
                    <li>
                        The Customer understands and has become familiar with
                        technical requirements necessary to use the Services and
                        has no objections in respect thereof. The Customer is
                        aware of risk and threats connected with electronic data
                        transmission.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. reserves the right to access
                        Customer accounts for the technical and administrative
                        purposes and for security reasons. The obtained
                        information in such a manner shall not be processed or
                        made available to any third parties unless required by
                        provisions of law.
                    </li>{' '}
                    <li>
                        The Customers of Aboardme / Timvork Inc. Service
                        declares that they will not use the Service in the way
                        that may constitutes a violation of laws.
                    </li>{' '}
                    <li>
                        Violation of this Terms and Conditions, applicable laws
                        or generally accepted norms and rules shall lead to the
                        termination of this Agreement.
                    </li>
                </ol>{' '}
                <h2 id="acceptable-use-policy">Acceptable Use Policy</h2>{' '}
                <ol>
                    <li>
                        This Acceptable Use Policy applies to Aboardme / Timvork
                        Inc. Services accessible through{' '}
                        <a href="https://www.aboardme.com">aboardme.com</a>,{' '}
                        <a href="http://www.chatbot.com">chatbot.com</a>,{' '}
                        <a href="http://www.knowledgebase.ai">
                            knowledgebase.ai
                        </a>
                        , mobile versions and successors URLs related to the
                        domain or subdomain;
                    </li>{' '}
                    <li>
                        To ensure proper and fluent functioning of the system we
                        hereby declare that Aboardme / Timvork Inc. Customers
                        shall not misuse the Services and product. Consequently,
                        the Customers agree not to:
                        <ol>
                            <li>
                                hinder functioning of the Services especially in
                                the form of reverse engineering or hacking the
                                Services, attempting to gain unauthorized access
                                to the Services (or any portion thereof) or
                                related systems, networks or data,
                            </li>{' '}
                            <li>
                                use the Services in a way contradictory to this
                                Terms and Conditions and causing a real danger
                                for the Aboardme / Timvork Inc., for example use
                                the Services to generate or send unsolicited
                                communications or communication judged to be a
                                spam, or otherwise cause Aboardme / Timvork Inc.
                                to become impaired in its ability to send
                                communications on its own or on its Customers',
                            </li>{' '}
                            <li>
                                misrepresent or mask the origin of any data,
                                content or other information you submit for
                                example by "spoofing", "phishing", manipulating
                                headers or other identifiers, impersonating
                                anyone else or access the Services via another
                                Customer's account without their permission,
                            </li>{' '}
                            <li>
                                use the Services in a way which violates the
                                rights of other individuals or laws,
                            </li>{' '}
                            <li>
                                promote or advertise products or services
                                different the one belonging to the Customer
                                without legal basis,
                            </li>{' '}
                            <li>
                                allow or encourage anyone else to commit any of
                                the actions listed above.
                            </li>
                        </ol>
                    </li>{' '}
                    <li>
                        By creating an account (license) Customer consents to
                        the collection, use, storage, processing, and disclosure
                        of their information, including Personal Data, as well
                        as data subject employed while using the Service
                        (Agents), in accordance with Aboardme / Timvork Inc.'s
                        Privacy Policy. Customer also agrees that Aboardme /
                        Timvork Inc. may process as a data processor, Personal
                        Data of users visiting Customer's website (where
                        Aboardme, Inc''s Services are implemented) but not using
                        the Service. It applies when the visitor of the
                        Customer's website enter their personal data into the
                        website beyond the Service. Customer agrees and warrants
                        that they complies with all applicable data protection
                        laws (including The General Data Protection Regulation
                        EU <sup>2016</sup>⁄<sub>679</sub>) while collecting and
                        processing their customers', End-users and visitors'
                        personal data gained for the purpose of accessing the
                        Service or in another way acquired and transferred to
                        the Company. In particular the Customer is obliged to
                        gaining consents for data processing (or to demonstrate
                        other basis of data processing) and to gain consents for
                        data transfer, including cross-border transfer. Customer
                        is responsible for the lawful acquisition and processing
                        of personal data transferred to Aboardme / Timvork Inc.
                        through the Service.
                    </li>{' '}
                    <li>
                        As a condition of using the Services the Customer shall
                        (a) as required by applicable law, provide notice to its
                        customers, (End-users) and obtain consent if required
                        for use of Aboardme / Timvork Inc. Services as well as
                        for processing and transferring Agents and End-users
                        Personal Data to the Company and its Third Party Service
                        Providers (including but not limited to the consent for
                        processing a sensitive data if required); (b) be
                        responsible for its employees, representatives,
                        End-users, and Agents that use the Services; © comply
                        with any limitations or restrictions set forth in these
                        Agreement, and (d) use the Services only in compliance
                        with applicable law.
                    </li>{' '}
                    <li>
                        All information, data, text, software, graphics,
                        commentary, video, messages, or any other materials
                        submitted by using the Service, (collectively, "Data"),
                        is the sole responsibility of the person from whom such
                        Data originated. Customer is wholly responsible for all
                        download, uploaded or otherwise transmitted via any of
                        the Services. Aboardme / Timvork Inc. is not responsible
                        for the Data that the Customer submits in a Services.
                    </li>{' '}
                    <li>
                        The Customer acknowledges and agrees that the Company
                        may engage Sub-processors in connection with the
                        provision of the Services, including without limitation
                        the Processing of the Customers Personal Data.
                    </li>{' '}
                    <li>
                        Customer acknowledges and agrees that by downloading
                        Application (defined in the Developers Terms) from the
                        Apps Marketplace, the Customer agrees that their
                        Personal Data will be made accessible to the Developer
                        who published Application via Apps Marketplace. The
                        Customer agrees and acknowledges that Aboardme / Timvork
                        Inc. has no responsibility for providing any support
                        and/or maintenance for Applications from the Apps
                        Marketplace. Failure of a Developer to provide support
                        and maintenance will not entitle the Customer to any
                        refund of fees paid or compensation, if any, for the
                        Application or the Service.
                    </li>
                </ol>{' '}
                <h2 id="trademarks-and-copyrights">
                    Trademarks and copyrights
                </h2>{' '}
                <ol>
                    <li>
                        "Aboardme" is a trademark registered in the United
                        States Patent and Trademark Office and is therefore
                        subject to national as well as international protection.
                    </li>{' '}
                    <li>
                        The Aboardme / Timvork Inc. states that it has rights to
                        intangible assets in the form of a graphic project of
                        offered services and software, website layout, computer
                        software as well as to all company signs, symbols and
                        trademarks used within its scope of business activity.
                        These assets are protected respectively by: an act dated
                        4th February 1994- Copyright and Neighboring Rights; an
                        act dated 16th April 1993- Combating of Unfair
                        Competition; an act dated 30th June 2000-Industrial
                        Property Rights; European Union Law and other binding
                        international agreements.
                    </li>{' '}
                    <li>
                        The Aboardme / Timvork Inc. websites listed above and
                        all information, content, material, graphics, products
                        (including any software) and services included on or
                        otherwise made available to the Visitors and the
                        Customer through the aforementioned websites are its
                        exclusive property and are covered by an act on
                        copyright and neighboring rights.
                    </li>{' '}
                    <li>
                        Website addresses as well as their contents and layouts
                        are protected by the aforesaid acts.
                    </li>{' '}
                    <li>
                        The Aboardme / Timvork Inc. websites and all
                        information, content, material, products (including any
                        software) and services included on or otherwise made
                        available to the Customer through the aforementioned
                        websites are provided by "as is" and "as available"
                        basis unless specified otherwise in the Agreement. The
                        Customer accepts the fitness of the Services and product
                        for the purpose.
                    </li>{' '}
                    <li>
                        Prior written consent of Aboardme / Timvork Inc. is
                        required for any not permitted business and non-business
                        use of offered Services and products. Such consent is
                        required in particular when disseminating and
                        publicizing particular elements (e.g. photos, films,
                        texts) in other Internet services/websites, printed
                        publications, books, multimedia presentations and in
                        other electronic media as well as for the disposal or
                        use of its work (adaptations, alterations,
                        modifications).
                    </li>{' '}
                    <li>
                        Unauthorized lending, sale, granting of further licenses
                        and sublicenses to the offered products and services by
                        the Customer or any other entity or person without
                        express consent of Aboardme / Timvork Inc. is
                        prohibited. Such acts are not deemed to be the proper
                        use of Services.
                    </li>{' '}
                    <li>
                        The Customer must not modify or change the purpose and
                        use of offered Services and products. Misleading others
                        as to the existence of cooperation, association,
                        relationship or acting on behalf of Aboardme / Timvork
                        Inc. is prohibited.
                    </li>
                </ol>{' '}
                <h2 id="payments">Payments</h2>{' '}
                <ol>
                    <li>
                        The use of offered Services available on the websites
                        listed above is free of charge for the first several
                        weeks depending on the Service.
                    </li>{' '}
                    <li>
                        After that free trial period if the Customer wishes to
                        continue using our Services he or she will be requested
                        to make a payment according to currently effective price
                        list placed on the individual website.
                    </li>{' '}
                    <li>
                        All payments shall be non-cash transactions, conducted
                        electronically by external professional entities.
                    </li>{' '}
                    <li>
                        Non-payment according to currently effective price list
                        means resignation from the provided Services.
                    </li>{' '}
                    <li>
                        The receipts for all payments will be provided
                        electronically and stored in the product as PDF files.
                    </li>{' '}
                    <li>
                        The Customer shall be given a 14-day period notice of
                        all changes in the price list.
                    </li>
                </ol>{' '}
                <h2 id="refund-policy">Refund Policy</h2>{' '}
                <ol>
                    <li>
                        Regardless of the billing cycle, the Company provides no
                        refunds or credits for unused time of the Services or
                        plan downgrades if you decide to close your account
                        before the end of your subscription period.
                    </li>{' '}
                    <li>
                        There will be no exceptions in order to treat all
                        Customers equally and to keep our administrative costs
                        low for the ultimate benefit of our Customers.
                    </li>{' '}
                    <li>
                        Details for trial account, paid subscription and
                        canceling the account are as follows:
                        <ol>
                            <li>
                                Trial license. After signing up you get a new,
                                fully functional Service account . The free
                                trial is valid for a several weeks (depending on
                                the Service) because we want you to test the
                                Service in your own business environment and get
                                to know our product well before you make your
                                decision about subscription. During your trial
                                period, the credit card is not required.
                            </li>{' '}
                            <li>
                                Paid subscription. Once you decide to subscribe,
                                we need you to provide your credit card details,
                                number of Agents (operators) seats and
                                subscription period – however, we will not
                                charge you immediately after submitting your
                                details, but upon the end of your free trial. We
                                do not offer refunds on already processed
                                payments – this policy is the same for all
                                Customers – and you are responsible for all
                                charges in your subscription.
                            </li>{' '}
                            <li>
                                Canceling the license. You can cancel your
                                account (license) at any time. After canceling,
                                your license will stop working immediately.
                                Credit from previous payments will be lost and
                                is not refundable.
                            </li>
                        </ol>
                    </li>
                </ol>{' '}
                <h2 id="data-protection-compliance">
                    Data Protection Compliance
                </h2>{' '}
                <ol>
                    <li>
                        Aboardme / Timvork Inc. is dedicated to protecting data
                        protection and to promote compliance with rules set
                        forth by, among others, European Union.
                    </li>{' '}
                    <li>
                        This Agreement shall be supplemented by General Data
                        Protection Compliance form available through{' '}
                        <a href="/gdpr-compliance">this link</a>.
                    </li>{' '}
                    <li>
                        Any observation or breach of data protection may be
                        reported via{' '}
                        <a href="mailto:support@aboardme.com">
                            support@aboardme.com
                        </a>
                    </li>
                </ol>{' '}
                <h2 id="account-closing">Account closing</h2>{' '}
                <ol>
                    <li>
                        The Customer may close its account at any time. Account
                        closing means that the further use of Services with the
                        use of current password and login shall no longer be
                        possible.
                    </li>{' '}
                    <li>
                        The Customer is responsible for closing his account.
                    </li>{' '}
                    <li>
                        Closing the account by the Customer before the end of
                        settling period for which a payment has been made does
                        not obligate Aboardme / Timvork Inc. to refund the
                        amount for the unused period.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. reserves the right to close
                        Customer's account due to gross infringement of the
                        provisions of these Terms and Conditions or in the event
                        of illegal use of the offered Services.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. shall not be liable for any
                        damage suffered by the Customer or the End-user arisen
                        due to the suspension or closing the account by the
                        Customer or for other reasons arising from the
                        Customer's fault.
                    </li>
                </ol>{' '}
                <h2 id="guarantee-and-limited-liability">
                    Guarantee and limited liability
                </h2>{' '}
                <ol>
                    <li>
                        Aboardme / Timvork Inc. guarantees the highest quality
                        of its operations to ensure accessibility and continuity
                        of offered Services and products in accordance with
                        their use and purpose.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. does not guarantee compatibility
                        of offered Services and products with other producers'
                        software. The Customer shall bear responsibility for the
                        choice and consequences following from the use of other
                        software including its applicability to the Customer's
                        objectives. Please be aware that due to the complexity
                        of long-distance data transmission there is no
                        possibility to ensure an absolute security,
                        accessibility and continuity of the provided Service.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. stipulates that opinions given
                        by users do not reflect in any possible manner Company's
                        views and opinions. Aboardme / Timvork Inc. does not
                        monitor or control Customer's opinions on a continual
                        basis; if, however, any opinions in breach with these
                        Terms and Conditions should be revealed efforts shall be
                        immediately made to remove them as soon as possible.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. shall bear no liability in
                        particular for:
                        <ol>
                            <li>
                                all negative consequences being the result of
                                force majeure,
                            </li>{' '}
                            <li>
                                phrases and entries added to the network in
                                connection with the use of offered Services,
                            </li>{' '}
                            <li>
                                unlawful and inconsistent with these Terms and
                                Conditions Customer's operations while using
                                offered Services and products,
                            </li>{' '}
                            <li>
                                disturbances in accessibility of offered
                                products and Services not caused by Aboardme,
                                Inc.,
                            </li>{' '}
                            <li>
                                damage suffered by the Customer, End-user or any
                                other person or entity arisen due to the
                                suspension or closing the account by the
                                Customer or for other reasons arising from the
                                Customer's fault,
                            </li>{' '}
                            <li>
                                damage suffered by the Customer as a result of a
                                third party using his data that enable him to
                                access the provided Services and products,
                            </li>{' '}
                            <li>
                                damage following from the Customer or the
                                impossibility to use offered products and
                                Services including damage actually suffered, the
                                loss of expected benefits, data loss, damage or
                                computer crash, the costs of substitute
                                equipment and software, shut-down, company
                                reputation infringement,
                            </li>{' '}
                            <li>
                                consequences of failure to perform or improper
                                performance of obligations undertaken by other
                                users even though such an obligation has been
                                undertaken using offered products and Services.
                            </li>
                        </ol>
                    </li>
                </ol>{' '}
                <h2 id="privacy-policy">Privacy Policy</h2>{' '}
                <p>
                    Principles of Customer privacy protection including Personal
                    Data protection have been described in a separate{' '}
                    <a href="/privacy-policy">Privacy Policy</a>. For
                    information regarding Aboardme's, Inc. compliance with the
                    Privacy Shield Frameworks, please visit our Privacy Policy.
                </p>{' '}
                <h2 id="final-provisions">Final provisions</h2>{' '}
                <ol>
                    <li>
                        Aboardme / Timvork Inc. reserves the right to update the
                        Terms and Conditions and Privacy Policy at any time
                        without notice. The most current version of these
                        documents can be reviewed by clicking on the "Terms and
                        Conditions" and{' '}
                        <a href="/privacy-policy">"Privacy Policy"</a> at the
                        bottom of our website.
                    </li>{' '}
                    <li>
                        All disputes arising in connection with these Terms and
                        Conditions shall be primarily resolved amicably.
                    </li>{' '}
                    <li>
                        Aboardme / Timvork Inc. is open to set up a mediation in
                        case of any conflict.
                    </li>{' '}
                    <li>
                        All disputes that cannot be amicably resolved shall be
                        submitted for resolution by Common Court of Law at the
                        location of Aboardme / Timvork Inc. principal office.
                    </li>{' '}
                    <li>
                        The provisions of Civil Code, the act on copyright and
                        neighboring rights, an act on electronic services
                        providing and other provisions of effective law shall be
                        applicable in issues not governed by these Terms and
                        Conditions.
                    </li>
                </ol>
            </article>
        </div>
    )
}
