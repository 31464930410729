import React, { Component } from 'react'
import './LoginPage.css'
//Redux imports
import { connect } from 'react-redux'

import { SignInWithPhone } from './SignIn'
import { Redirect } from 'react-router-dom'
import { MDBContainer } from 'mdbreact'
import BrandLogo from '../components/BrandLogo'

class LoginPage extends Component {
    render() {
        const { auth } = this.props
        if (auth.uid) return <Redirect to="/app/dashboard" />
        return (
            <MDBContainer>
                <div className="text-center">
                    <BrandLogo className="text-center" />
                </div>
                <SignInWithPhone />
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
    }
}

export default connect(mapStateToProps)(LoginPage)

