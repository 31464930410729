import React, { Component } from 'react'
import Switch from 'react-switch'

class Toggle extends Component {
    render() {
        const { id, checked, handleChange } = this.props
        return (
            <label>
                <Switch
                    id={id}
                    onChange={value =>
                        handleChange({ target: { id: id, value: value } })
                    }
                    checked={checked}
                />
            </label>
        )
    }
}

export default Toggle
