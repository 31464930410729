import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'

export default function EnterConfirmCodeForm(props) {
    const { updateCode, onConfirmCode } = props
    const { isCodeSent, isSending, error } = props.user
    return (
        <MDBRow
            center
            className={
                !isCodeSent
                    ? 'animated fadeOutDown'
                    : 'animated fadeInUp confirm-code'
            }
        >
            <MDBCol md="12" className="mt-4">
                <input
                    type="number"
                    onChange={e => updateCode(e.target.value)}
                    name="code"
                    className="form-control verification-code"
                    placeholder="Enter verification code"
                    disabled={!isCodeSent || isSending}
                />

                <div className="text-center mt-2">
                    <MDBBtn
                        color="blue"
                        onClick={onConfirmCode}
                        disabled={!isCodeSent || isSending}
                    >
                        Confirm Code
                    </MDBBtn>
                    {error && <p>{error.message}</p>}
                </div>
            </MDBCol>
        </MDBRow>
    )
}
