import React, { Component } from 'react'
import { MDBContainer } from 'mdbreact'
import Redirect from 'react-router/Redirect';
import { connect } from 'react-redux'

class ChatPage extends Component {
    render() {
        const { auth } = this.props
        if (!auth.uid) return <Redirect to="/login" />
        return (
            <MDBContainer>
                <h2>Chat Page</h2>
            </MDBContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        firebase: state.firebase,
    }
}

export default connect(mapStateToProps)(ChatPage)