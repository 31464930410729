export const firebaseConfigProd = {
  apiKey: "AIzaSyCi4X1A5WdM7XYcHgzot2qNUdsqMs_zjvk",
  authDomain: "aboardmeapp.firebaseapp.com",
  databaseURL: "https://aboardmeapp.firebaseio.com",
  projectId: "aboardmeapp",
  storageBucket: "aboardmeapp.appspot.com",
  messagingSenderId: "731506260741"
};

export const firebaseConfigDev = {
  apiKey: "AIzaSyCi4X1A5WdM7XYcHgzot2qNUdsqMs_zjvk",
  authDomain: "aboardmeapp.firebaseapp.com",
  databaseURL: "https://aboardmeapp.firebaseio.com",
  projectId: "aboardmeapp",
  storageBucket: "aboardmeapp.appspot.com",
  messagingSenderId: "731506260741"
};
